@import "@/assets/styles/global/lib.scss";






















.sample-code-group-item {
  border-bottom: size(1) solid var(--regular-text-inv);
  &:last-child {
    border-bottom: 0;
  }
}

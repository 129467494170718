@import "@/assets/styles/global/lib.scss";




































































































.graphql-popup {
  &.slidein-popup {
    position: fixed;
    z-index: var(--slidein-z-index);
    top: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    --slidein-title-pad-bottom: #{size(8)};
    --slidein-header-pad-top: #{size(16)};
    --slidein-header-pad-left: #{size(10)};
    --slidein-content-pad-top: #{size(14)};
    --slidein-content-pad-left: #{size(34)};
    --response-menu-width: #{size(200)};

    --link-indicator-height: #{size(12)};
    --link-indicator-width: #{size(9)};
    @media screen and (min-width: $breakpoint-lg) {
      --slidein-title-pad-bottom: #{size(18)};
      --slidein-header-pad-top: #{size(18)};
      --slidein-header-pad-left: #{size(24)};
      --slidein-content-pad-top: #{size(22)};
      --slidein-content-pad-left: #{size(44)};
      --response-menu-width: #{size(245)};
      --link-indicator-height: #{size(14)};
      --link-indicator-width: #{size(11)};
    }

    .svg-icon {
      fill: #fff;
    }

    &.keepParentFocus {
      left: auto;
    }

    .slidein-empty-layer {
      background: #f0ebff47;
      flex: 1 0 auto;
    }

    .slidein-container-layer {
      // background: green;
      height: 100%;
      &.fluid-container {
        width: 100%;
      }
    }

    .slidein-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 100vw;
      box-shadow: 10px 0px 20px 0px rgb(0, 0, 0);
      background: var(--regular-text-inv);

      .header {
        justify-content: space-between;
        padding-bottom: 0;
        background: #202746;
        .title {
          padding:
            size(10)
            var(--slidein-header-pad-left)
            size(10)
            0;
          position: relative;
          cursor: pointer;
          @media screen and (min-width: $breakpoint-md) {
            pointer-events: none;
            padding:
            var(--slidein-header-pad-top)
            var(--slidein-header-pad-left)
            var(--slidein-header-pad-top)
            0;
            border-bottom: 0;
            cursor: default;
          }
          &.desktop {
            border-bottom: size(1) solid #191f38;
          }
          h4 {
            font-size: size(16);
            padding-bottom: 0;
            color: #fff;
            padding-left: size(25);
            font-weight: 600;
            @media screen and (min-width: $breakpoint-md) {
              font-size: size(18);
            }
          }
          p {
            color: var(--secondary-text);
            font-size: size(18);
          }
        }

        h4 {
          font-size: var(--title-4-font-size);
          padding-bottom: var(--slidein-title-pad-bottom);
        }

        p, span.p {
          font-size: var(--small-text-font-size);
          letter-spacing: 0.09em;
        }
      }

      > .content {
        overflow: auto;

        &.split-cols {
          height: 100%;
        }
      }
    }

    .response-menu {
      background: #1f2746;
      flex-basis: var(--response-menu-width);
      padding-top: size(45);
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      margin-left: size(22);
      overflow-y: auto;
      &.expanded {
        display: block;
        height: 100%;
      }
      @media screen and (min-width: $breakpoint-md) {
        background: #191f38;
        display: block;
        position: initial;
        margin-left: 0;
        padding-top: size(32);
      }
      .response-link {
        padding: size(10) size(5) size(10) 0;
      }
      .preview-button {
        text-align: left;
        color: #fff;
        position: relative;
        svg {
          display: none;
        }
        &:before {
          content: '';
          display: none;
          height: var(--link-indicator-height);
          width: var(--link-indicator-width);
          position: absolute;
          @media screen and (min-width: $breakpoint-md) {
            display: block;
          }
        }
        &:hover {
          &::before {
            background: #fff;
          }
        }
        &.active {
          color: #fff;
          &::before {
            background: var(--master-highlight);
          }
        }
      }

      h3 {
        font-size: var(--title-4-font-size);
        line-height: size(26);
        color: #fff;
        font-weight: bold;
        padding-left: size(8);
        padding-bottom: size(8);
        @media screen and (min-width: $breakpoint-md) {
          padding-left: size(16);
          padding-bottom: size(20);
        }
      }
    }

    .icon-box {
      width: size(10);
      min-width: size(10);
    }

    .response-content {
      margin: 0;
      padding: 0;
      background: #202746;
      .tools {
        position: absolute;
        bottom: size(70);
        top: initial;
        right: size(20);
        display: flex;
        flex-direction: column;
        padding: 0;
        .download {
          order: 2;
        }
        &.header-tools {
          display: none;
          padding: 0;
          @media screen and (min-width: $breakpoint-md) {
            display: block;
            top: size(12);
            button {
              padding: 0;
              animation: none;
            }
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          top: size(18);
          bottom: initial;
          display: block;
        }
        .close-btn-inner {
          display: block;
          @media screen and (min-width: $breakpoint-md) {
            display: none;
          }
        }
        a, button {
          display: block;
          width: size(30);
          padding-left: 0;
          padding-right: 0;
          background: none;
          animation: none;
          img {
            max-width: 100%;
          }
          @media screen and (min-width: $breakpoint-md) {
            width: size(30);
          }
        }
        @media screen and (min-width: $breakpoint-md) {
           top: size(60);
        }
        @media screen and (min-width: $breakpoint-lg) {
          right: size(20);
          top: size(75);
        }
      }
    }

    .slidein-container-layer {
      // background: green;
      height: 100%;
      &.fluid-container {
        width: 100%;
        .title {
          display: flex;
          justify-content: space-between;
          @media screen and (min-width: $breakpoint-md) {
            display: block;
          }
        }
      }
      &:not(.fluid-container) {
        .title {
          cursor: default;
          overflow-x: auto;
          p {
            font-size: var(--small-text-font-size);
            color: var(--hero-text);
          }
          h4 {
            font-size: var(--title-4-font-size);
            color: var(--hero-text);
          }
        }

        .header {
          background: var(--box-color-1);
          padding-bottom: 0;
          display: flex;
        }

        .tools {
          position: initial;
          button, a {
            width: size(52);
            padding-left: size(10);
            padding-right: size(10);
          }
        }

        .response-content {
          padding: 0;
          margin-left: 0;
          .title {
            background: var(--box-color-1);
            padding: var(--slidein-header-pad-top) var(--slidein-header-pad-left);
          }
        }

        .json-preview {
          padding: var(--slidein-content-pad-top) 0 var(--slidein-content-pad-top) var(--slidein-content-pad-left);
        }

        ul {
          .json-preview {
            padding-left: size(16);
          }
        }
      }
    }
  }
}

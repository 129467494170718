@import "@/assets/styles/global/lib.scss";















































































section.section-sample-code {
  --subsection-pad-top: #{size(8)};
  --subsection-pad-left: #{size(12)};

  @media screen and (min-width: $breakpoint-md) {
    --subsection-pad-top: #{size(24)};
    --subsection-pad-left: #{size(28)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --subsection-pad-top: #{size(36)};
    --subsection-pad-left: #{size(48)};
  }
  &.sub-section {
    h2 {
      display: none;
    }
  }
  .box {
    padding-left: var(--section-pad-left);
    .loading-msg {
      text-align: left;
    }

    .box-content {
      padding:
      var(--section-box-content-pad-top)
      0
      var(--section-box-content-pad-bottom)
      var(--section-box-content-pad-left);

      background-color: var(--box-color-2);
      color: var(--secondary-text);
    }
  }

  &.sub-section {
    padding-top: var(----subsection-pad-top);
    padding-left: var(--subsection-pad-left);
    section.hero.sub-section {
      padding-left: 0;
      h2 {
        padding-left: var(--section-pad-left);
      }
    }
  }
}
section.page-support-section {
  background-color: var(--box-color-3);
}
.web-page {
  .page-support-section {
    margin-top: var(--support-section-top-pad);
  }
}
.guides-code-section {
  padding-top: size(30);
  @media screen and (min-width: $breakpoint-md) {
    padding-top: size(40);
  }
}

@import "@/assets/styles/global/lib.scss";



















































































































































































.api-data-http-status {
  --error-border: #B097FC;
  --error-bg: rgba(176, 151, 252, 0.2);
  --http-status-circle-size: #{size(30)};
  --table-header-title: #{size(36)};

  --td-first-pad-top: #{size(12)};
  --td-pad-top: #{size(12)};
  --td-pad-left: #{size(8)};
  --td-pad-bottom: #{size(12)};

  --http-status-font-size: #{size(14)};
  --http-status-line-height: #{size(17)};
  --http-error-font-size: #{size(14)};
  --http-error-line-height: #{size(18)};
  --http-reason-font-size: #{size(14)};
  --http-reason-line-height: #{size(17)};

  @media screen and (min-width: $breakpoint-md) {
    --http-status-circle-size: #{size(40)};
    --table-header-title: #{size(60)};

    --td-first-pad-top: #{size(38)};
    --td-pad-top: #{size(16)};
    --td-pad-left: #{size(16)};
    --td-pad-bottom: #{size(16)};

    --http-reason-font-size: #{size(16)};
    --http-reason-line-height: #{size(19)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --http-status-circle-size: #{size(50)};
    --table-header-title: #{size(70)};

    --td-first-pad-top: #{size(38)};
    --td-pad-top: #{size(16)};
    --td-pad-left: #{size(16)};
    --td-pad-bottom: #{size(16)};

    --http-status-font-size: #{size(16)};
    --http-status-line-height: #{size(19)};
    --http-reason-font-size: #{size(18)};
    --http-reason-line-height: #{size(24)};
  }

  text-align: left;
  font-size: var(--para-font-size);
  line-height: var(--para-line-height);

  .hide-on-desktop {
    display: inherit;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }
    table {
      th {
        &:first-child {
          width: size(100);
          @media screen and (min-width: $breakpoint-md) {
            width: size(150);
          }
        }
      }
    }
  }

  .show-on-desktop {
    display: none;

    @media screen and (min-width: $breakpoint-lg) {
      display: inherit;
    }
  }
  table {
    width: 100%;
    thead {
      th {
        line-height: var(--table-header-title);
        background: var(--regular-text-inv);
        white-space: nowrap;
        padding-left: var(--td-pad-left);
        &:first-child {
          @media screen and (min-width: $breakpoint-lg) {
            text-align: center;
          }
        }
      }
    }
    tr {
      &:first-child {
        td {
          padding-top: var(--td-first-pad-top);
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    td {
      padding:
        var(--td-pad-top)
        0
        var(--td-pad-bottom)
        var(--td-pad-left);

      &.http {
        max-width: size(110);
        @media screen and (min-width: $breakpoint-lg) {
          text-align: center;
        }
        .http-status-code {
          font-size: var(--http-status-font-size);
          line-height: var(--http-status-line-height);
        }
      }
      &.code {
        max-width: size(200);
        @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(20);
        }
        .json-path {
          background-color: var(--error-bg);
          border-left: size(4) solid var(--error-border);
          font-size: var(--http-error-font-size);
          line-height: var(--http-error-line-height);
          padding: 0 size(4);
          margin-bottom: 0;
        }
      }

      &.reason {
        min-width: size(200);
        max-width: size(300);
        font-size: var(--http-reason-font-size);
        line-height: var(--http-reason-line-height);
        @media screen and (min-width: $breakpoint-md) {
          min-width: size(440);
          max-width: size(440);
        }
        .status-reason {
          font-weight: bold;
          display: inline-block;
        }
        .desc-expand-icon , .inv-icon{
          display: inline-block;
          cursor: pointer;
          vertical-align: middle;
          margin-left: size(4);
          transition: transform 0.3s linear;
          transform: rotate(0deg);
        }
        .inv-icon {
          transition: transform 0.3s linear;
          transform: rotate(180deg);
        }
        .hide {
          display: none;
        }
        .desc {
          padding-top: size(12);
        }
        .response-items, .links {
          padding-top: size(12);
        }
      }

      &.spacer {
        padding: 0;
        width: var(--docs-page-pad-secondary-right);
        border-bottom: none;
      }
    }
    .hide {
      display: none;
    }
    .button-container {
      td {
        border-bottom: none;
        padding: 0;
        padding-top: size(16);
      }
      button {
        outline: none;
        border: none;
        font-size: var(--http-reason-font-size);
        line-height: var(--http-reason-line-height);
        margin-left: size(8);
        cursor: pointer;
        &.add-pad {
          padding-bottom: size(18);
        }
        &.remove-pad {
          padding-bottom: 0;
        }
      }
      .hide {
        display: none;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        width: size(20);
        height: size(20);
        @media screen and (min-width: $breakpoint-md) {
          width: size(30);
          height: size(30);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(20);
          height: size(20);
        }
      }
    }

    .reason-row {
      td {
        padding-top: 0;
        border-bottom: size(2) solid var(--regular-text-inv);
        &.spacer {
          border-bottom: none;
        }
      }
    }

    .no-border {
      td {
        border-bottom: none;
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    table {
      td {
        border-bottom: size(1) solid var(--regular-text-inv);
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";






















































































.products-group-outer {
  position: relative;
  .products-section-top {
    padding-top: var(--section-pad-top);
    padding-left: var(--section-pad-left);
    h2 {
      font-family: var(--hero-font);
    }
    .desc-area {
      .markdown span.p {
        font-size: var(--para-font-size);
        line-height: var(--para-line-height);
        color: var(--hero-text);
      }
    }
  }
}

.products-group {
  .product-section {
    &:nth-child(even) .box-content {
      background-color: var(--box-color-1);
    }
    &:nth-child(odd) .box-content {
      background-color: var(--box-color-2);
    }
  }
}

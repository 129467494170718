@import "@/assets/styles/global/lib.scss";














































.web-page{
  --web-page-pad-top: #{size(20)};
  --web-page-pad-right: #{size(16)};
  --web-page-pad-bottom: #{size(20)};
  --web-page-pad-left: #{size(0)};
  --web-page-pad-secondary-right: #{size(16)};
  --support-section-top-pad: #{size(27)};

  @media screen and (min-width: $breakpoint-md) {
    --web-page-pad-top: #{size(30)};
    --web-page-pad-right: #{size(24)};
    --web-page-pad-bottom: #{size(32)};
    --web-page-pad-left: #{size(28)};
    --web-page-pad-secondary-right: #{size(24)};
    --support-section-top-pad: #{size(60)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --web-page-pad-top: #{size(54)};
    --web-page-pad-right: #{size(55)};
    --web-page-pad-bottom: #{size(52)};
    --web-page-pad-left: #{size(46)};
    --web-page-pad-secondary-right: #{size(150)};
    --support-section-top-pad: #{size(100)};
  }

  padding:
    var(--web-page-pad-top)
    0
    var(--web-page-pad-bottom)
    0;

  .data-wrapper {
    display: flex;
    flex-wrap: wrap;
    .page-support-section {
      width: 100%;
      margin-top: 0;
    }
  }
}

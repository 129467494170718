@import "@/assets/styles/global/lib.scss";















































.graphql-code-display {
  margin: 0 var(--docs-page-pad-secondary-right) size(10) 0;
  position: relative;
  // max-width: size(600);
  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 var(--docs-page-pad-secondary-right) size(7) 0;
  }
  .code-content {
    padding-bottom: size(15);
  }
  .code-wrapper, .response-wrapper {
    width: 48%;
  }
  .response-wrapper {
    display: none;
    padding: size(25) ;
    @media screen and (min-width: $breakpoint-lg) {
      display: block;
    }
    &.add-border {
      border-left: size(1) solid #2f3b6a;
    }
  }
  .code-wrapper {
    position: relative;
    width: 100%;
    @media screen and (min-width: $breakpoint-lg) {
      width: 50%;
    }
    &.wrapper {
      padding: size(25);
      border-right: size(1) solid #2f3b6a;
    }
    .inner-wrapper {
      padding: size(25);
      height: 100%;
      &.reduce-height {
        height: size(230);
        overflow: auto;
      }
    }
    .header-wrapper {
      padding: size(25);
      border-top: size(1) solid #2f3b6a;
      h5 {
        color: var(--rs-white-color);
        font-weight: 600;
        font-size: size(14);
        font-family: var(--code-font);
      }
      .code-div {
        margin-top: size(12);
        span {
          display: block;
          font-family: var(--code-font);
          font-size: size(12);
          line-height: size(15);
          color: #979db4;
          &.content {
            padding-left: size(10);
            color: #8bcd07;
          }
        }
      }
    }
  }
  .section-title {
    .name {
      font-size: size(18);
      line-height: size(24);
      font-weight: bold;
      padding: 0 0 size(6);
      display: inline-block;
      color: var(--hero-text);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(22);
        line-height: size(20);
        padding: 0 0 size(15);
      }
    }
    .markdown {
      .p {
        padding-bottom: size(30);
        font-size: var(--para-font-size);
        line-height: var(--para-line-height);
        padding-top: var(--level-3-para-top);
        margin: 0;
        max-width: 100%;
        color: var(--secondary-text);
        padding-right: 0;
        strong {
          font-weight: bold;
        }
      }
    }
  }
  .code {
    overflow: auto;
    background-color: #202746;
    position: relative;
    border-radius: size(6);
    max-height: size(450);
    overflow: hidden;
    overflow-y: auto;
     display: flex;
    padding: 0;
    @media screen and (min-width: $breakpoint-md) {
      max-height: size(550);
    }
    @media screen and (min-width: $breakpoint-lg) {
      max-height: size(600);
    }
    .language-bash {
      font-size: size(12);
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(14);
      }
    }
    pre {
      word-wrap: break-word;
      padding: 0;
      margin: 0;
      color: var(--regular-text-inv);
      @media screen and (min-width: $breakpoint-lg) {
        padding: 0;
        margin: 0;
      }
    }

    code {
      font-family: var(--code-font);
      word-wrap: break-word;
    }
  }

  .copy-context {
    display: none;
  }

  .success-message {
    font-size: size(12);
    line-height: size(15);
    background: var(--gray-line);
    color: var(--regular-text);
    padding: size(5) size(10);
    border-radius: size(12);
    position: absolute;
    top: size(3);
    right: size(0);
    z-index: 999;
    transition: opacity 0.5s linear;
    animation: fadein  0.5s linear;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to  {
        opacity: 1;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      right: size(8);
    }
    @media screen and (min-width: $breakpoint-lg) {
      top: size(8);
      right: size(10);
    }
  }
  .copy-button {
    position: absolute;
    right: size(8);
    top: size(6);
    width: size(30);
    height: size(30);
    background-color: var(--hero-text);
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: size(26);
    .copy-icon {
      vertical-align: middle;
    }
    &:hover {
      opacity: 0.8;
    }
    @media screen and (min-width: $breakpoint-md) {
      top: size(12);
      right: size(22);
    }
    @media screen and (min-width: $breakpoint-lg) {
      top: size(15);
      right: size(24);
    }
  }
  &.sample-query-wrapper {
    margin-right: 0;
    .response-wrapper {
      overflow-y: auto;
    }
  }
  &.more-scenarios-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    .markdown {
      .p {
        padding-bottom: 0;
        font-size: var(--para-font-size);
        line-height: var(--para-line-height);
        padding-top: var(--level-3-para-top);
        padding-right: 0;
        strong {
          font-weight: bold;
        }
      }
    }
    .name {
      font-size: var(--title-4-font-size);
      font-weight: 700;
      padding: 0;
      color: var(--hero-text);
    }
  }
  &.popup-window {
    width: 100%;
    max-height: 100%;
    .code {
      max-height: 100%;
    }
    .code-wrapper {
      width: 94%;
      max-height: 100%;
      border-right: 0;
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        display: block;
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";
.page-docs-api {
  --section-pad-top: #{size(60)};
  --section-pad-top-first: #{size(20)};
  --section-para-top-top: #{size(4)};

  --section-box-pad-left: #{size(12)};
  --section-box-pad-top: #{size(20)};
 
  --section-box-content-pad-top: #{size(20)};
  --section-box-content-pad-bottom: #{size(20)};
  --section-box-content-pad-left: #{size(20)};
  --section-box-content-pad-right: #{size(0)};

  --level-3-para-top: #{size(5)};
  --level-3-content-top: #{size(21)};
  --level-3-content-left: #{size(0)};

  --page-margin-pad-bottom: #{size(34)};
  --json-preview-space-bottom: #{size(40)};

  --hero-title-z-index: 100;
  
  --toaster-msg-text-Color: #dedede;
  --url-btn-color: #E2E8F7;

  @media screen and (min-width: $breakpoint-md) {
    --section-pad-top: #{size(80)};
    --section-pad-top-first: #{size(28)};
    --section-para-top-top: #{size(12)};
    --section-box-pad-left: #{size(20)};
    --section-box-pad-top: #{size(28)};

    --section-box-content-pad-top: #{size(40)};
    --section-box-content-pad-bottom: #{size(40)};
    --section-box-content-pad-left: #{size(64)};
    --section-box-content-pad-right: #{size(0)};
    --level-3-para-top: #{size(12)};
    --level-3-content-top: #{size(12)};
    --level-3-content-left: #{size(16)};

    --page-margin-pad-bottom: #{size(54)};
    --json-preview-space-bottom: #{size(86)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --section-pad-top: #{size(100)};
    --section-pad-top-first: #{size(100)};
    --section-para-top-top: #{size(16)};

    --section-box-pad-left: #{size(20)};
    --section-box-pad-top: #{size(32)};

    --section-box-content-pad-top: #{size(64)};
    --section-box-content-pad-bottom: #{size(64)};
    --section-box-content-pad-left: #{size(144)};
    --section-box-content-pad-right: #{size(0)};

    --level-3-para-top: #{size(18)};
    --level-3-content-top: #{size(28)};
    --level-3-content-left: #{size(20)};

    --page-margin-pad-bottom: #{size(78)};
    --json-preview-space-bottom: #{size(130)};
  }

  padding:
    var(--docs-page-pad-top)
    0
    var(--docs-page-pad-bottom)
    var(--docs-page-pad-left);

  h2 {
    font-family: var(--hero-font);
    font-weight: bold;  
    display: inline-block;
    padding-right: size(10);  
  }
  .url-btn {
    display: none;
    border-radius: 100%;
    background: var(--url-btn-color);
    cursor: pointer;
    outline: none;
    border: none;
    padding: size(6) size(7) size(2);
  }
  .toaster-msg {
    position: fixed;
    top: 15%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    display: inline-block;
    padding: size(10) size(25);
    border-radius: size(4);
    background: var(--regular-text);
    color: var(--toaster-msg-text-Color);
    font-size: size(12);
    z-index: var(--junior-menu-z-index);
  }

  h1 {
    font-family: var(--regular-font);
    font-weight: 400;  
    font-size: var(--title-font-size);
    line-height: var(--title-line-height);
    letter-spacing: var(--title-letter-space);
    color: var(--hero-text);
    padding-bottom: var(--title-pad-bottom);
    @media screen and (min-width: $breakpoint-md) {
      font-weight: bold;
    }
    &.hero-title {
      font-family: var(--hero-font);
      font-weight: bold;
      font-size: var(--section-title-font-size);
      padding: size(20) 0 size(5);
      @media screen and (min-width: $breakpoint-md) {
        font-weight: bold;
        padding-top: 0;
        padding-bottom: var(--title-pad-bottom);
        font-size: var(--title-font-size);
        line-height: var(--title-line-height);
      }
    }
  
  }

  #fantasy-points-system, #playground-intro {
    a {
      font-size: size(14);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(24);
      }
    }
    img {
      max-height: size(180);
      padding-top: size(20);
      @media screen and (min-width: $breakpoint-md) {
        max-height: size(285);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-height: size(515);
      }

    }
  }

  .hero-title-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-line);
    margin-bottom: size(20);

    cursor: pointer;

    h1 {
      padding-bottom: size(13);
    }

    .icon-box {
      margin-top: -2px; //🤷‍♀️
      margin-right: 2px; //🤷‍♀️
      .svg-icon {
        width: size(10);
        height: size(10);
      }
    }
  }
  .hero {
    padding-right: var(--junior-menu-pad-right);

    .show-on-mobile {
      position: fixed;
      top: size(36);
      left: 0;
      right: 0;
      padding: size(20) size(16) 0;
      background: var(--regular-text-inv);
      z-index: var(--hero-title-z-index);
      .hero-title-block {
        margin-bottom: 0;
      }
    }

    .desc-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: size(15);
      @media screen and (min-width: $breakpoint-md) {
        padding-top: 0;
      }
      .hero-p {
        font-family: var(--hero-font);
        font-size: var(--para-hero-font-size);
        line-height: var(--para-hero-line-height);
        letter-spacing: 0.01em;
        max-width: var(--para-max-width);
        color: var(--hero-text);
        width: 100%;
        @media screen and (min-width: $breakpoint-lg) {
          width: 60%;
        }
        .markdown {
          a {
            text-decoration: underline;
            color: var(--hero-text);
          }
        }
      }

      .image-area {
        min-height: var(--hero-img-height);
        img {
          min-height: var(--hero-img-height);
          padding-top: size(10);
          @media screen and (min-width: $breakpoint-lg) {
            min-height: 100%;
            padding-top: 0;
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: 40%;
        }
      }
    }
  }
  section.page-support-section {
    margin-top: var(--section-pad-top);
    background-color: var(--box-color-3);
    padding-top: 0;
  }
  .error-page {
    section {
      &.page-related-api {
        padding-top: 0;
      }
    }
  }
  section {
    padding-top: var(--section-pad-top);
    &.hover-element{
      &:hover {
        .url-btn {
          display: inline-block;
        }
      }
    }
    &:empty {
      padding-top: 0;
    }

    &.first-section {
      padding-top: var(--section-pad-top-first);
    }
    &.page-schema-section {
      >p {
        padding-top: 0;
        .markdown {
          .p {
            padding-top: var(--section-para-top-top);
          }
        }
      }
    }
    // > h2 {
    //   font-size: var(--title-2-font-size);
    //   line-height: var(--title-2-line-height);
    //   font-weight: bold;
    //   color: var(--hero-text);
    //   padding-right: var(--docs-page-pad-secondary-right);
    // }
    &.downloads {
      .box {
        .box-content {
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: size(24);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-bottom: var(--section-box-content-pad-bottom);
          }
        }
      }
    }
    >p, .markdown > p, .markdown > span.p {
      font-size: var(--para-title-font-size);
      color: var(--secondary-text);
      line-height: 1.3;

      a {
        color: var(--hero-text);
        text-decoration: underline;
      }
    }
    >p {
      padding-top: var(--section-para-top-top);
      padding-right: var(--docs-page-pad-secondary-right);
    }
    .box {
      padding-top: var(--section-box-pad-top);
      padding-left: var(--section-box-pad-left);

      .loading-msg {
        text-align: left;
      }

      .box-content {
        padding:
        var(--section-box-content-pad-top)
        0
        var(--section-box-content-pad-bottom)
        var(--section-box-content-pad-left);

        background-color: var(--box-color-2);
        color: var(--secondary-text);

        &.bg-white {
          background-color: var(--regular-text-inv);
          border: size(1) solid var(--gray-line);
        }
        a {
          color: var(--hero-text);
          text-decoration: underline;
        }

        .page-tabs {
          a {
            color: var(--secondary-text);
            text-decoration: none;
          }
        }

        h3, .title-level-3 {
          font-size: var(--title-4-font-size);
          font-weight: bold;
          color: var(--hero-text);
        }

        p, span.p {
          font-size: var(--para-font-size);
          line-height: var(--para-line-height);
        }

        td, p, span.p {
          a {
            text-decoration: underline;
          }
        }

        .box-content-item {
          border-bottom: size(1) solid var(--regular-text-inv);
          margin: size(27) var(--docs-page-pad-secondary-right) 0 0;
          padding-bottom: size(20);

          .markdown {
            ul {
              margin-left: size(15);
              li {
                list-style-type: disc;
              }
            }
            strong {
              font-weight: bold;
              color: var(--hero-text);
            }
          }
      
          &:first-child {
            margin-top: size(0);
            .item-title {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: size(24);
            }
          }
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .para-level-3 {
            padding-top: var(--level-3-para-top);
            .markdown {
              .p {
                padding-bottom: var(--section-para-top-top);
                &:last-child {
                  padding-bottom: 0;
                }
                img{
                  text-align:center;
                  max-width: 100%;
                }
              }
            }
          }

          .content-level-3 {
            padding-top: var(--level-3-content-top);
            padding-left: var(--level-3-content-left);
            &:empty {
              padding-top: 0;
              padding-left: 0;
            }
          }
          
          .markdown {
            ol >li {
              margin-top: size(15);
            }
          }
        }
      }
    }

    &.bg-2 {
      .box-content {
        background-color: var(--box-color-1);
      }
    }

    &.inner-margin {
      .box {
        .box-content {
          padding:
          var(--section-box-content-pad-top)
          0
          0
          0;

          .page-margin {
            padding:
            0
            var(--section-box-content-pad-right)
            0
            var(--section-box-content-pad-left);
            &.page-space-bottom{
              padding-bottom: var(--page-margin-pad-bottom);
            }
          }
        }
      }
    }

    &.pull-left {
      margin-left: calc(-1 * var(--docs-page-pad-left));
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    h1 {
      font-family: var(--hero-font);
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .hero {
      h1 {
        // To avoid Junior Menu overlay
        max-width: size(810);
      }
      .desc-area {
        flex-direction: row; 
        .hero-p {
          padding-bottom: var(--section-pad-top);
        }
       
        .image-area {
          min-width: size(335);
        }
      }
    }
  }

  // Temporary fix, till design changes.
  .explores {
    .variable-box {
      word-break: break-word;
    }
  }

}
@import "@/assets/styles/global/lib.scss";





































.api-data-res-schema {
  --expand-bar-color: #DEEDED;
  --expanded-bar-pad-top: #{size(20)};

  @media screen and (min-width: $breakpoint-md) {
    --expanded-bar-pad-top: #{size(40)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --expanded-bar-pad-top: #{size(64)};
  }

  --expanded-bar-height: #{size(45)};
  transition: all 1s ease-in-out;

  .schema-container {
    max-height: size(430);
    width: 100%;
    overflow: hidden;
  }
  .page-margin {
    &.add-padding {
      padding-bottom: var(--section-box-content-pad-top)!important;
    }
  }
  .expand-bar {
    background-color: var(--expand-bar-color);
    padding: 0 size(26);
    cursor: pointer;
    .show-close{
      display: inline-block!important;
    }
    .show-expand {
      display: inline-block !important;
    }
    .expand-bar-container {
      &.remove-expand-bar {
        display: none;
      }
      button {
        display: inline-block;
        padding: size(15) 0 size(15) size(8);
        outline: none;
        text-decoration : none;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(16) 0 size(16) size(8);
        }
      }
      .collapse-icon , .expand-icon {
        display: none;
        vertical-align: top;
        cursor: pointer;
        margin-top: size(12);
        width: size(20);
        height: size(20);
        @media screen and (min-width: $breakpoint-md) {
          margin-top: size(7);
          width: size(30);
          height: size(30);
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-top: size(12);
          width: size(20);
          height: size(20);
        }
      }
    }
  }
  .page-margin {
    padding-right: 0;
    .collapse {
      display: inline-block;
      position: absolute;
      top: size(24);
      right: size(16);
      cursor: pointer;
      @media screen and (min-width: $breakpoint-md) {
        top: size(36);
        right: size(56);
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(48);
        right: size(80);
      }
    }
    .hide {
      display: none;
    }
  }
  .expanded {
    position: fixed;
    z-index: var(--dailog-z-index);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--box-color-2);

    .expand-bar {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
    }

    .page-margin {
      padding-bottom: var(--expanded-bar-height) !important;
      height: 100%;
      padding-top: var(--expanded-bar-pad-top)!important;
    }

    .schema-container {
      height: calc(100% - var(--expanded-bar-height));
      max-height: none;
      overflow: auto;
      & .json-space-bottom{
        padding-bottom: var(--json-preview-space-bottom);
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";



































































.api-data-cache{
  .variable-box {
    padding: size(1) size(3);
    border-radius: size(2);
  }
  ul {
    padding: size(20);
    li {
      padding-top: size(10);
      display: flex;

      .desc {
        padding-left: size(20);
      }
    }
  }

  .desc-sub-section {
    margin-left: size(20);
    margin-top: size(24);
    @media screen and (min-width: $breakpoint-lg) {
      margin-left: size(77);
    }
    p {
      padding-top: 0;
    }

    .desc-sub-section-item {
      margin-top: size(16);
    }
    .item-json-path {
      margin-bottom: size(12);
      .json-path {
        margin-bottom: 0;
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";






















































































































































































































































































.data-structure {
  position: relative;
  &.data-structure-1, &.data-structure-5 ,
  &.data-structure-docs-1, &.data-structure-docs-5{
    section {
      &.data-card {
        .section-desc-area {
          .hero-p {
            .markdown {
              .p {
                color: var(--master-text);
              }
            }
          }
        }
      }
    }
  }
  &.data-structure-5, &.data-structure-6 ,
  &.data-structure-docs-5, &.data-structure-docs-6{
    width: 100%;
    margin-bottom: size(60);
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: size(80);
      flex: 0 0 50%;
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(100);
    }
    section {
      &.data-card {
        .section-desc-area {
          .hero-p {
            span {
              &.p {
                padding-bottom: 0;
              }
            }
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(38);
          .section-desc-area {
            .hero-p {
              span {
                &.p {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(60);
          .section-desc-area {
            .hero-p {
              padding-left: 0;
              span {
                &.p {
                  padding-bottom: 0;
                }
              }
              h2 {
                margin-top: 0;
              }
            }
          }
        }
        .image-area {
          @media screen and (min-width: $breakpoint-md) {
            position: absolute;
            width: size(40);
            min-height: size(38);
            top: size(36);
            left: size(152);
            padding-bottom: 0;
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(58);
            min-height: size(56);
            top: size(60);
            left: size(230);
          }
        }
      }
    }
  }
  &.data-structure-5,
  &.data-structure-docs-5 {
    margin-bottom: 0;
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(100);
    }
    section {
      &.data-card {
        .image-area {
          @media screen and (min-width: $breakpoint-md) {
            position: absolute;
            width: size(58);
            min-height: size(55);
            top: size(36);
            left: size(150);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(90);
            min-height: size(85);
            left: size(220);
            top: size(52);
          }
        }
      }
    }
  }
  section {
    &.data-card {
      padding: var(--data-section-pad-top)
        var(--data-section-pad-right)
        var(--data-section-pad-bottom)
        var(--data-section-pad-left);
      @media screen and (min-width: $breakpoint-md) {
        padding-right: 0;
        padding-bottom: 0;
      }
      .section-desc-area {
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: $breakpoint-md) {
          align-items: center;
        }
        .hero-p {
          h2 {
            display: inline-block;
            font-family: var(--hero-font);
            font-size: var(--data-structure-title-font-size);
            line-height: var(--data-structure-title-height);
            color: var(--master-text);
            padding: var(--title-pad-top) var(--title-pad-side);
            background-color: var(--title-pad-bg);
            border-radius: size(3);
            margin-bottom: size(16);
            @media screen and (min-width: $breakpoint-lg) {
              margin-bottom: size(24);
              margin-top: size(20);
            }
          }
          .markdown {
            .p {
              font-size: var(--data-structure-desc-font-size);
              line-height: var(--data-structure-desc-height);
              font-family: var(--regular-font);
              margin-right: 0;
              max-width: var(--section-para-max-width);
              color: var(--secondary-text);
              padding-right: 0;
              @media screen and (min-width: $breakpoint-md) {
                flex: 0 0 45%;
                padding-bottom: var(--data-section-pad-bottom);
              }
              @media screen and (min-width: $breakpoint-lg) {
                flex: 0 0 60%;
              }
            }
          }
        }
      }
    }
  }
}
.data-card {
  .section-desc-area {
    .hero-p {
      text-align: center;
      @media screen and (min-width: $breakpoint-md) {
        text-align: left;
        padding-right: size(24);
      }
    }
    .line-path-map {
      img {
        position: absolute;
        z-index: 3;
      }
      span {
        display: inline-block;
        width: size(60);
        height: size(26);
        position: absolute;
        bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          width: size(80);
          height: size(36);
        }
        @media screen and (min-width: $breakpoint-lg) {
          width: size(108);
          height: size(44);
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  .image-area {
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      width: size(266);
      min-height: size(172);
      display: flex;
      flex: 1 0 auto;
      padding-bottom: size(70);
      .image-item {
        img, picture {
          max-height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      width: size(530);
      min-height: size(288);
      padding-bottom: size(60);
    }
  }
}

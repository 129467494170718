@import "@/assets/styles/global/lib.scss";



















.api-sample-code {
  color: var(--secondary-text);
  .page-tabs {
    a {
      cursor: pointer;
    }
  }
}

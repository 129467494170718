@import "@/assets/styles/global/lib.scss";













.api-data-related-apis {
  color: var(--hero-text);

  h3 {
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-top: var(--section-box-pad-top);

    li {
      padding: 0 size(28) size(28) 0;
    }
  }
  .btn {
    &.smaller-on-mobile {
      @media screen and (min-width: $breakpoint-md) {
        padding: size(14) size(16);
      }
    }
  }
}

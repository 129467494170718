@import "@/assets/styles/global/lib.scss";






















.senior-menu{
  width: var(--senior-menu-width);

  display: none;
  position: fixed;
  z-index: var(--senior-menu-z-index);
  top: calc(var(--master-menu-height) + var(--hero-title-height) + #{size(16)}) ;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.4s, top 0.4s;
  transition-delay: 0.2s;
  // transition:
  //   top
  //   var(--master-menu-transition-duration);
  // transition-delay: var(--master-menu-transition-duration);

  overflow: auto;
  background: var(--regular-text-inv);

  &.expanded {
    display: block;
  }

  &.hide {
    display: none;
  }

  &.slide-out {
    @media screen and (min-width: $breakpoint-md) {
      transform: translateX(-100%);
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    padding: size(16) 0;
    top: var(--master-menu-height);
    display: block;
  }

  @media screen and (min-width: $breakpoint-lg) {

  }
  .toggle-switch-container {
    align-content: center;
    padding-left: size(24);
    padding-bottom: size(20);
    margin: size(20) 0;
  }

  .toggle-switch {
    margin: 0;
  }

  .toggle-switch {
    width: size(10);
    position: relative;
  }

  .toggle-switch input {
    position: absolute;
    top: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  .toggle-switch input:checked {
    z-index: 1;
  }

  .toggle-switch input:checked + label {
    opacity: 1;
    cursor: default;
  }

  .toggle-switch input:not(:checked) + label:hover {
    opacity: 0.5;
  }

  .toggle-switch label {
    color: var(--regular-text);
    opacity: 0.33;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }

  .toggle-switch .toggle-outside {
    height: 100%;
    border-radius: size(17);
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
  }

  .toggle-switch .toggle-inside {
    border-radius: 50%;
    background: var(--regular-text-inv);
    position: absolute;
    transition: all 0.3s ease-in-out;
  }

  .switch-vertical {
    background-color: var(--hero-text);
    width: 0;
    height: size(45);
    margin-left: size(20);
  }

  .switch-vertical input {
    height: 100%;
    width: size(12);
    right: 0;
    margin: 0;
  }

  .switch-vertical label {
    font-size: size(14);
    line-height: size(25);
    display: block;
    width: size(62);
    height: 48%;
    margin: 0;
    text-align: left;
    padding-left: size(12);
  }

  .switch-vertical .toggle-outside {
    background: var(--hero-text);
    position: absolute;
    width: size(16);
    height: 100%;
    right: 0;
    top: 0;
  }

  .switch-vertical .toggle-inside {
    height: size(10);
    left: size(3);
    top: size(3);
    width: size(10);
  }

  .switch-vertical input:checked ~ .toggle-outside .toggle-inside {
    top: size(3);
  }

  .switch-vertical input ~ input:checked ~ .toggle-outside .toggle-inside {
    top: size(31);
  }

}

@import "@/assets/styles/global/lib.scss";

































































































































































































































































































































































































































































































































































.graphql-docs {
  .loader {
    padding-top: size(6);
    color: #e6e4e4;
  }
  section {
    .api-sample-code {
      color: var(--secondary-text);
      .page-tabs {
        a {
          cursor: pointer;
        }
      }
    }
    &.downloads {
      .downloads-wrapper {
        .wrapper {
          margin-right: size(20);
          margin-bottom: size(15);
          @media screen and (min-width: $breakpoint-lg) {
            margin-right: size(30);
            margin-bottom: size(20);
          }
        }
        @media screen and (min-width: $breakpoint-lg) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
    & > .markdown {
      .p {
        padding-top: 0;
        padding-right: 0;
      }
    }
    .markdown {
      .p {
        padding-top: var(--section-para-top-top);
        padding-right: var(--docs-page-pad-secondary-right);
      }
      img {
        max-height: size(180);
        padding-top: size(20);
        @media screen and (min-width: $breakpoint-md) {
          max-height: size(285);
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-height: size(515);
        }

      }
    }
    & > img {
      display: block;
      max-height: size(180);
      padding-top: size(20);
      @media screen and (min-width: $breakpoint-md) {
        max-height: size(285);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-height: size(515);
      }

    }
    h2 {
      text-transform: capitalize;
    }
    &.getting-started, &.explores {
      & > a, .link-item {
        margin-top: size(15);
        background: var(--hero-text);
        border-color: var(--hero-text);
        color: var(--regular-text-inv);
      }
    }
    &.getting-started {
      padding: size(15);
      margin-top: var(--section-pad-top);
      background: var(--master-bg-lite);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(20);
      }
      svg {
        width: size(28);
        height: size(28);
        fill: var(--secondary-text);
        vertical-align: top;
        margin-right: size(5);
      }
      .markdown {
        display: inline-block;
        vertical-align: top;
        width: 88%;
        .p {
          font-size: size(16);
          line-height: size(24);
          text-align: left;
          width: 100%;
          padding: 0;
          a {
            font-size: size(16);
            line-height: size(24);
          }
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(18);
            line-height: size(26);
            a {
              font-size: size(18);
              line-height: size(26);
            }
          }
        }
      }
    }
    &.sample-code {
      .box {
        padding-left: 0;
        padding-top: size(30);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(50);
          padding-left: 0
        }
        .box-content {
          border: none;
          background: var(--box-color-2);
        }
      }
    }
    &.more-scenarios {
      .scenarios-wrapper {
        background-color: var(--box-color-1);
        padding: var(--section-box-content-pad-top)
        0 var(--section-box-content-pad-bottom) var(--section-box-content-pad-left);
        margin-top: var(--section-box-pad-top);
        margin-left: var(--section-box-pad-left);
      }
      .api-data-desc {
        margin-top: 0;
        padding-top: size(15);
        padding-left: var(--level-3-content-left);
        @media screen and (min-width: $breakpoint-md) {
          padding-top: var(--level-3-content-top);
          margin-top: 0;
        }
      }
      .box {
        padding: 0 0 size(20);
        border-bottom: size(1) solid var(--regular-text-inv);
        margin: size(27) var(--docs-page-pad-secondary-right) 0 0;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
  .api-data-desc {
    margin-top: size(15);
    .response-items {
      .response-item {
        &:empty {
          padding: 0;
        }
      }
    }
  }
  .popup-wrapper {
    display: flex;
    height: 100%;
    .code {
      border-radius: 0;
    }
    .code-group {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        width: 50%;
        display: block;
      }
      pre {
        height: 100%;
      }
    }
    .graphql-preview-wrapper {
      background: #242d50;
      width: 100%;
      @media screen and (min-width: $breakpoint-lg) {
        width: 50%;
      }
      .inner-wrapper {
        background: #242d50;
        padding: size(25);
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";



























































































































































































































































































































































.api-data-http-status {
  table {
    tbody {
      tr {
        td {
          .status-reason {
            font-weight: bold;
            display: inline-block;
          }
        }
      }
    }
  }
}

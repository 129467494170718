@import "@/assets/styles/global/lib.scss";














































.version-block-outer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: size(10);
  z-index: 100;
  .app-version{
    --env-bg-color: #333;
    --env-text-color: #DEDEDE;
    &.dev {
      --env-bg-color: #EEAA66;
      --env-text-color: #5E3C1B;
    }
    display: flex;
    align-items: center;
    flex-flow: column;
    .versions {
      flex: 1;
      font-size: size(12);
      ul {
        color: var(--env-bg-color);
        background-color: var(--env-text-color);
        padding: size(3);
        width: size(130);
        li {
          line-height: size(20);
        }
      }
    }
    .btn {
      background-color: var(--env-bg-color);
      color: var(--env-text-color);
      border: none;
      line-height: size(14);
      svg {
        width: size(16);
        vertical-align: middle;
        margin-top: -size(2);
        .cls2 {
          fill: var(--env-text-color);
        }
      }
      span {
        display: inline-block;
        vertical-align: middle;
        padding-left: size(4);
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";
















































.api-data-desc {
  .response-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    .response-item {
      padding-bottom: size(8);
      .preview-button {
        svg {
          width: size(20);
          height: size(20);
          min-width: size(20);
          @media screen and (min-width: $breakpoint-md) {
            width: size(30);
            height: size(30);
          }
        }
      }
    }

    &.use-column {
      flex-direction: row;

      .response-item {
        padding-right: 0;
        padding-bottom: size(16);
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          padding-right: size(35);
          width: auto;
        }
      }
    }
  }

  .item-desc {
    padding-bottom: size(20);

    p, .variable-box {
      color: var(--hero-text);
      text-transform: uppercase;

      font-size: size(12) !important;
      line-height: 1 !important;
      letter-spacing: 0.09em !important;
    }

    .variable-box {
      color: inherit;
      padding: size(1) size(3);
      border-radius: size(2);
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

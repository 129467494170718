@import "@/assets/styles/global/lib.scss";

































section {
  padding-top: var(--section-pad-top);
  padding-left: var(--section-pad-left);

  h2 {
    font-family: var(--hero-font);
    font-size: var(--section-title-font-size);
    line-height: var(--section-title-line-height);
    margin-bottom: size(10);
  }
  &.dual-column{
    .section-desc-area {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (min-width: $breakpoint-md) {
        .hero-p {
          column-count: 2;
          column-gap: size(25);
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        .hero-p {
          column-count: 2;
          column-gap: size(40);
          padding-right: size(40);
        }
      }
    }
  }
  &.wrap-text-around {
    .section-desc-area {
      @media screen and (min-width: $breakpoint-lg) {
        .image-item {
          float: left;
          max-width: size(480);
          margin: 0 size(10) size(10) 0;
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .section-desc-area {
      flex-direction: row;

      .image-area {
        min-width: size(335);
      }
    }
  }
}

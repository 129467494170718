@import "@/assets/styles/global/lib.scss";
.error-page {
  --section-box-content-pad-top: #{size(20)};
  --section-box-content-pad-bottom: #{size(20)};
  --section-box-pad-left: #{size(12)};

  @media screen and (min-width: $breakpoint-md) {
    --section-box-content-pad-top: #{size(40)};
    --section-box-content-pad-bottom: #{size(40)};
    --section-box-pad-left: #{size(20)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --section-box-content-pad-top: #{size(64)};
    --section-box-content-pad-bottom: #{size(64)};
  }

  ul {
    padding-right: size(16);
  }

  .image-area {
    height: var(--error-page-image-height);
    padding-right: size(16);
  }

  .flex-col {
    padding-bottom: size(60);
  }
  h1 {
    font-size: var(--error-page-title-font-size);
    font-weight: bold;
    line-height: var(--error-page-line-height);
    letter-spacing: var(--title-letter-space);
    color: var(--regular-text)!important;
    margin: size(16) 0 0!important;
    padding-bottom: size(32)!important;
  }

  .page-related-api {
    li {
      padding: size(8) 0;
    }
    p {
      padding-top: 0;
      padding-right: 0;
      color: var(--regular-text-color);
      font-size: var(--error-page-content-font-size);
      line-height: var(--error-page-content-line-height);
    }
  }

  &.http404 {
    .page-related-api {
      padding-bottom: size(32);
    }
  }

  &.coming-soon {
    --error-page-image-height: #{size(254)};
    text-align: center;
    h1{
      text-transform: capitalize;
    }
    .image-area {
      padding-bottom: size(40);
    }
    .desc-area {
      p {
        padding-bottom: size(30);
      }
    }
    ul {
      padding-bottom: size(60);
    }
  }
  
  section.pull-left {
    margin-left: calc(-1 * var(--docs-page-pad-left));
    .support-section {
      text-align: initial;
      margin-bottom: size(54);
    }
  }
  section.page-support-section {
    background-color: var(--box-color-3);
    margin-top: 0!important;
  }

  @media screen and (min-width: $breakpoint-md) {
    .desc-area {
      p {
        padding-bottom: 0;
      }
    }

    .flex-col {
      display: flex;
    }

    &.http404 {
      .desc-area {
        max-width: size(445);
        order: 2;
        align-self: flex-end;
      }
    }

    &.coming-soon {
      --error-page-image-height: #{size(347)};
      .desc-area {
        p {
          padding-bottom: size(60);
        }
      }
      p {
        margin: 0 auto;
      }
      ul {
        padding-bottom: size(120);
      }
      .page-related-api {
        ul {
          li {
            display: inline-block;
            margin-left: size(12);
            &:first-child {
              margin-left: 0;
            }
            div{
              display: inline-block;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &.http404 {
      .desc-area {
        max-width: size(816);
      }
    }

    .flex-col {
      padding-bottom: size(100);
    }
    
    .desc-area {
      p {
        max-width: size(740);
      }
      .page-related-api {
        padding-top: size(80);
        li {
          display: inline-block;
          padding: 0.5rem size(14);
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }

    &.coming-soon {
      .desc-area p {
        padding-right: 0;
      }
      .page-related-api {
        ul {
          li {
            margin-left: size(28);
          }
        }
      }
    }
  }
}
@import "@/assets/styles/global/lib.scss";


















































































































































































































































































.query-structure {
  .desc-column {
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
    .markdown {
      .p {
        padding-top: 0;
        strong {
          font-weight: bold;
        }
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";























.page-container {
  max-width: var(--content-max-width);
  margin: auto;
}
.docs-wrap {
  margin-top: var(--master-menu-height);
}

@import "@/assets/styles/global/lib.scss";

















































































.web-page{
  --web-page-pad-top: #{size(20)};
  --web-page-pad-right: #{size(16)};
  --web-page-pad-bottom: #{size(20)};
  --web-page-pad-left: #{size(0)};
  --web-page-pad-secondary-right: #{size(16)};
  --support-section-top-pad: #{size(27)};

  @media screen and (min-width: $breakpoint-md) {
    --web-page-pad-top: #{size(30)};
    --web-page-pad-right: #{size(24)};
    --web-page-pad-bottom: #{size(32)};
    --web-page-pad-left: #{size(28)};
    --web-page-pad-secondary-right: #{size(24)};
    --support-section-top-pad: #{size(60)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --web-page-pad-top: #{size(54)};
    --web-page-pad-right: #{size(55)};
    --web-page-pad-bottom: #{size(52)};
    --web-page-pad-left: #{size(46)};
    --web-page-pad-secondary-right: #{size(150)};
    --support-section-top-pad: #{size(100)};
  }

  padding: var(--docs-page-pad-top) 0 var(--docs-page-pad-bottom) 0;

  .data-wrapper {
    display: flex;
    flex-wrap: wrap;
    .page-support-section {
      width: 100%;
      margin-top: 0;
    }
  }

  .show-on-mobile {
    position: fixed;
    top: size(36);
    left: 0;
    right: 0;
    padding: size(20) size(16) 0;
    background: var(--regular-text-inv);
    z-index: 100;
    .hero-title-block {
      margin-bottom: 0;
    }
  }

  .hero-title-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-line);
    margin-bottom: size(20);

    cursor: pointer;

    h1 {
      padding-bottom: size(13);
      font-family: var(--regular-font);
      font-weight: 400;
      font-size: var(--title-font-size);
      line-height: var(--title-line-height);
      letter-spacing: var(--title-letter-space);
      color: var(--hero-text);
    }

    .icon-box {
      margin-top: -2px; //🤷‍♀️
      margin-right: 2px; //🤷‍♀️
      .svg-icon {
        width: size(10);
        height: size(10);
      }
    }
  }

}

@import "@/assets/styles/global/lib.scss";





























.junior-menu {

  --junior-menu-dropdown-size: #{size(12)};
  --junior-menu-title-font-size: #{size(14)};

  @media screen and (min-width: $breakpoint-lg) {
    --junior-menu-title-font-size: #{size(16)};
    --junior-menu-dropdown-size: #{size(20)};
  }

  position: fixed;
  z-index: var(--junior-menu-z-index);
  right: var(--junior-menu-pad-right);
  top: var(--master-menu-height);
  margin-top: var(--junior-menu-pad-top);

  padding: 0 size(32);
  background: var(--junior-menu-inactive);
  color: var(--secondary-text);
  width: calc(var(--junior-menu-width) - (32 * 2));
  max-width: calc(var(--junior-menu-width) - (32 * 2));
  height: var(--junior-menu-height);
  overflow: hidden;

  transition: all 350ms ease-out;
  display: none;

  &:hover, &.expanded {
    background: var(--hero-text);
    color: var(--regular-text-inv);

    a {
      color: var(--regular-text-inv);
    }

    .default-stroke {
      stroke: var(--regular-text-inv);
    }

  }

  &.expanded {
    height: size(240);
    @media screen and (min-width: $breakpoint-lg) {
      height: size(260);
    }
    &.height {
      height: size(190);
    }
  }

  .goto {
    font-size: var(--junior-menu-title-font-size);
    display: flex;
    justify-content: space-between;
    height: var(--junior-menu-height);
    cursor: pointer;

    :nth-child(1) {
      @include flex-center;
    }

    :nth-child(2) {
      @include flex-center;

      .svg-icon {
        width: var(--junior-menu-dropdown-size);
        height: var(--junior-menu-dropdown-size);
      }
    }
  }

  ul {
    font-size: size(12);
    line-height: size(12);
    letter-spacing: 0.09em;
    text-transform: uppercase;
    li {
      &:first-child {
        padding-top: size(8);
      }
      a {
        padding-bottom: size(12);
        display: block;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}
.master-menu-overlay {
  .junior-menu:not(.expanded) {
    background-color: var(--master-bg-lite);
    &:hover {
      background-color: var(--hero-text);
    }
  }
}

@import "@/assets/styles/global/lib.scss";







.page-goto-top {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: rotate(-90deg);
  transform-origin: right;
  z-index: var(--goto-top-z-index);
  display: none;
  cursor: pointer;

  @media (any-pointer: fine) {
    display: block;
  }

  @media screen and (min-width: $breakpoint-lg) {
    display: block;
  }

  .label {
    padding-right: size(22);
    font-size: size(12);
    line-height: size(15);
    display: inline-block;
    overflow: hidden;
    color: var(--hero-text);
    letter-spacing: 0.09em;
    text-transform: uppercase;
    vertical-align: middle;
  }

  img {
    height: size(15);
    vertical-align: middle;
    // Block space while image loading
    min-width: size(24);
  }
}

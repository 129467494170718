@import "@/assets/styles/global/lib.scss";





svg{
    .cls-1 {
        fill: #fff;
    }
    .cls-2 {
        fill: #f6e337;
    }
    .cls-3 {
        fill: url(#linear-gradient);
    }
    .cls-4 {
        opacity: 0.05;
        isolation: isolate;
    }
    .cls-5 {
        fill: #c33436;
    }
    .cls-6 {
        fill: #c83739;
    }
    .cls-7 {
        fill: #123693;
    }
}

@import "@/assets/styles/global/lib.scss";
.graphql-preview {
    // Following CSS has become a mess
    // Rewrite when possible
    &.change-text-color {
      --field-type-color: #657B83;
    }
    --field-name-color: var(--regular-text-inv);
  
    --field-type-color: #333;
    --field-type-link-color: #0053D6;
    --field-type-enum-color: #0053D6;
  
    --field-type-collection-bg-color: rgba(255, 255, 255, 0);
    --field-type-collection-color: #D33682;
  
    --field-border-line-color: var(--gray-line);
    --field-border-level-1-color: #dd4a68;
    --field-border-level-2-color: #c76b29;
    --field-border-level-3-color: rgb(230, 228, 228);
    --field-border-level-4-color: #c76b29;;
  
    --field-value-type-empty:  #c76b29;
    --field-value-type-object: #9b9b9b;
    --field-value-type-array: #00AA58;;
    --field-value-type-null: #29b973;
    --field-value-type-string: #22a2c9;
    --field-value-type-number: #FAC03B;
    --field-value-type-boolean: #79BAF8;
    --field-value-type-punctuation :#979db4;
  
  
    font-size: size(12);
    line-height: size(17);
    font-weight: 400;
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(14);
    }
    span {
      &:empty {
        display: none;
      }
    }
  
    .help-text {
      font-size: 0.9rem;
      color: #141140;
    }
    .list-wrapper {
      padding-left: 1rem;
    }
    .type-display {
      padding-right: 2rem;
    }
  
    .field>.name, .field>.type, .field .type-name{
      font-family: var(--code-font);
      font-style: normal;
      display: inline-block;
    }
    .field>.name {
      color: var(--field-name-color);
    }
  
    // .field>.type {
    //   max-width: size(350);
    // }
  
    .dataclass-wrapper .field>.name:not(.required):after {
      content: 'o';
      font-size: 80%;
      vertical-align: super;
    }
  
  
    .field>.type .type-name {
      color: var(--field-type-color);
    }
  
    .field>.type .type-name.link {
      color: var(--field-type-link-color);
      text-decoration: underline;
      cursor: pointer;
    }
  
    .field>.type .type-name.link.enum {
      color: var(--field-type-enum-color);
    }
  
    .field>.type .type-collection-display {
      background-color: var(--field-type-collection-bg-color);
      padding: 0;
      display: inline-block;
    }
  
    .field>.type .type-collection-wrapper {
      color: var(--field-type-collection-color);
      line-height: 1.1;
      font-size: 0.8rem;
    }
  
  
    .dataclass__field__line {
      padding: size(5) 0;
      overflow: auto;
    }
  
    .dataclass__field__line>:first-child {
      padding-right: 2rem;
      min-width: 12.08rem;
      display: inline-block;
    }
  
    .child-level .dataclass__field__line:last-child {
      border-bottom: none;
    }
  
    .dataclass-wrapper.child-level {
      padding-left: 1rem;
      margin-top: 0.5rem;
    }
  
  
  
    .dataclass-wrapper.level-1 .dataclass__field__line>:first-child {
      min-width: 11rem;
      color: var(--field-border-level-2-color);
    }
  
    .dataclass-wrapper.level-2 .dataclass__field__line>:first-child {
      min-width: 9.9rem;
      color: var(--field-border-level-3-color);
    }
  
    .dataclass-wrapper.level-3 .dataclass__field__line>:first-child {
      min-width: 8.89rem;
      color: var(--field-border-level-4-color);
    }
  
    .value {
      .defined {
        font-style: italic;
      }
      .empty {
        color: var(--field-value-type-empty);
      }
      .object {
        color: var(--field-value-type-object);
      }
  
      .array {
        color: var(--field-value-type-array);
      }
  
      .expand-btn {
        cursor: pointer;
        img {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  
    .exapandable {
      cursor: pointer;
    }
  
    .value-type-null, .value-type-undefined {
      color: var(--field-value-type-null);
    }
  
    .value-type-string {
      color: var(--field-value-type-string);
      &.punctuation {
        color: var(--field-value-type-punctuation);
      }
    }
  
    .value-type-number {
      color: var(--field-value-type-number);
    }
  
    .value-type-boolean {
      color: var(--field-value-type-boolean);
    }
  
    &.collapsed {
      display: none;
    }
  
    &.compact-width {
      &.dataclass-wrapper {
        &.level-0, &.level-1, &.level-2, &.level-3  {
          .dataclass__field__line {
            :first-child {
              padding-right: size(4);
              min-width: auto;
            }
          }
        }
      }
    }
  }
@import "@/assets/styles/global/lib.scss";




























































































.slidein-popup {
  position: fixed;
  z-index: var(--slidein-z-index);
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;

  --slidein-title-pad-bottom: #{size(8)};
  --slidein-header-pad-top: #{size(16)};
  --slidein-header-pad-left: #{size(10)};
  --slidein-content-pad-top: #{size(14)};
  --slidein-content-pad-left: #{size(34)};
  --response-menu-width: #{size(200)};

  --link-indicator-height: #{size(12)};
  --link-indicator-width: #{size(9)};
  @media screen and (min-width: $breakpoint-lg) {
    --slidein-title-pad-bottom: #{size(18)};
    --slidein-header-pad-top: #{size(18)};
    --slidein-header-pad-left: #{size(24)};
    --slidein-content-pad-top: #{size(22)};
    --slidein-content-pad-left: #{size(44)};
    --response-menu-width: #{size(268)};
    --link-indicator-height: #{size(14)};
    --link-indicator-width: #{size(11)};
  }

  &.keepParentFocus {
    left: auto;
  }

  .slidein-empty-layer {
    background: #f0ebff47;
    flex: 1 0 auto;
  }

  .slidein-container-layer {
    // background: green;
    height: 100%;
    &.fluid-container {
      width: 100%;
    }
  }

  .slidein-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100vw;
    box-shadow: 10px 0px 20px 0px rgb(0, 0, 0);
    background: var(--regular-text-inv);

    .header {
      justify-content: space-between;
      padding-bottom: size(32);
      .title {
        padding:
          0
          var(--slidein-header-pad-left)
          size(10)
          0;
        position: relative;
        border-bottom: 1px solid var(--gray-line);
        cursor: pointer;
        @media screen and (min-width: $breakpoint-md) {
          pointer-events: none;
          padding:
          0
          var(--slidein-header-pad-left)
          var(--slidein-header-pad-top)
          0;
          border-bottom: 0;
          cursor: default;
        }
        h4 {
          font-size: size(16);
          padding-bottom: 0;
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(24);
          }
        }
        p {
          color: var(--secondary-text);
          font-size: size(18);
        }
      }

      .tools {
        padding:
          calc(var(--slidein-header-pad-top) - var(--btn-tiny-pad-top))
          calc(var(--slidein-header-pad-left) - var(--btn-tiny-pad-left));
      }

      h4 {
        font-size: var(--title-4-font-size);
        padding-bottom: var(--slidein-title-pad-bottom);
      }

      p, span.p {
        font-size: var(--small-text-font-size);
        letter-spacing: 0.09em;
      }
    }

    > .content {
      overflow: auto;

      &.split-cols {
        height: 100%;
      }
    }
  }

  .response-menu {
    background: #fff;
    flex-basis: var(--response-menu-width);
    padding-top: size(32);
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    top: size(70);
    margin-left: size(22);
    overflow-y: auto;
    &.expanded {
      display: block;
      height: 100%;
    }
    @media screen and (min-width: $breakpoint-md) {
      background: var(--box-color-1);
      display: block;
      position: initial;
      margin-left: 0;
    }
    .response-link {
      padding: size(10) size(5) size(10) 0;
    }
    .preview-button {
      text-align: left;
      color: var(--secondary-text);
      position: relative;
      svg {
        display: none;
      }
      &:before {
        content: '';
        display: none;
        height: var(--link-indicator-height);
        width: var(--link-indicator-width);
        position: absolute;
        @media screen and (min-width: $breakpoint-md) {
          display: block;
        }
      }
      &:hover {
        &::before {
          background: var(--hero-text);
        }
      }
      &.active {
        color: var(--hero-text);
        &::before {
          background: var(--master-highlight);
        }
      }
    }

    h3 {
      font-size: var(--title-4-font-size);
      line-height: size(26);
      color: var(--hero-text);
      font-weight: bold;
      padding-left: size(8);
      padding-bottom: size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding-left: size(16);
      }
    }
  }

  .icon-box {
    width: size(10);
    min-width: size(10);
  }

  .response-content {
    margin-left: size(22);
    padding: size(18) size(22) size(18) 0;
    @media screen and (min-width: $breakpoint-md) {
      margin-left: size(36);
      padding: size(32) size(28) size(32) 0;
    }
    .tools {
      position: absolute;
      bottom: size(70);
      top: initial;
      right: size(20);
      display: flex;
      flex-direction: column;
      .download {
        order: 2;
      }
      @media screen and (min-width: $breakpoint-md) {
        top: size(70);
        bottom: initial;
        display: block;
      }
      a, button {
        display: block;
        width: size(30);
        padding-left: 0;
        padding-right: 0;
        img {
          max-width: 100%;
        }
        @media screen and (min-width: $breakpoint-md) {
          width: size(30);
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        top: size(24);
      }
      @media screen and (min-width: $breakpoint-lg) {
        right: size(84);
        top: size(32);
      }
    }
  }

  .slidein-container-layer {
    // background: green;
    height: 100%;
    &.fluid-container {
      width: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        @media screen and (min-width: $breakpoint-md) {
          display: block;
        }
      }
    }
    &:not(.fluid-container) {
      .title {
        cursor: default;
        overflow-x: auto;
        p {
          font-size: var(--small-text-font-size);
          color: var(--hero-text);
        }
        h4 {
          font-size: var(--title-4-font-size);
          color: var(--hero-text);
        }
      }

      .header {
        background: var(--box-color-1);
        padding-bottom: 0;
        display: flex;
      }

      .tools {
        position: initial;
        button, a {
          width: size(52);
          padding-left: size(10);
          padding-right: size(10);
        }
      }

      .response-content {
        padding: 0;
        margin-left: 0;
        .title {
          background: var(--box-color-1);
          padding: var(--slidein-header-pad-top) var(--slidein-header-pad-left);
        }
      }

      .json-preview {
        padding: var(--slidein-content-pad-top) 0 var(--slidein-content-pad-top) var(--slidein-content-pad-left);
      }

      ul {
        .json-preview {
          padding-left: size(16);
        }
      }
    }
  }
}

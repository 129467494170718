@import "@/assets/styles/global/lib.scss";














.preview-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  font-size: size(10);
  letter-spacing: 0.09em;
  text-transform: uppercase;
  transition: color 0.3s linear , content 0.3s linear;
  color: var(--hero-text);

  svg, span {
    display: block;
  }

  span {
    text-align: left;
    font-size: size(12);
    padding: size(8) 0 size(8) size(8)
  }

  &:hover {
    color: var(--secondary-text);
    svg{
      circle {
        fill: #0053D6;
      }
      path {
        fill: #fff;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: size(12);

    span {
      padding: 0 0 0 size(16)
    }
  }
}

@import "@/assets/styles/global/lib.scss";





































.senior-menu-list{
  --menu-top: #{size(24)};
  --link-top: #{size(32)};
  --link-left: #{size(32)};
  --link-indicator-height: #{size(12)};
  --link-indicator-width: #{size(10)};
  --level-n-left-pad: #{size(2)};
  --font-size: #{size(16)};

  &.level-first {
    ul {
      &>li {
        &:first-child {
          .level-1 {
            .menu-name {
              padding-top: var(--menu-top);
              @media screen and (min-width: $breakpoint-md) {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    --menu-top: #{size(20)};
    --link-top: #{size(8)};
    --link-left: #{size(20)};
    --link-indicator-height: #{size(12)};
    --link-indicator-width: #{size(10)};
    --level-n-left-pad: #{size(3)};
    --font-size: #{size(12)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --menu-top: #{size(36)};
    --link-top: #{size(10)};
    --link-left: #{size(24)};
    --link-indicator-height: #{size(14)};
    --link-indicator-width: #{size(11)};
    --level-n-left-pad: #{size(9)};
    --font-size: #{size(14)};
  }

  // padding-bottom: size(16);
  font-size: var(--font-size);

  .menu-name {
    font-weight: 700;
    padding: var(--menu-top) var(--link-left) calc(var(--link-top) / 2) var(--link-left);
  }

  .link-line {
    display: flex;

    .active-indicator {
      flex-basis: var(--link-indicator-width);
      @include flex-center;

      .box {
        width: 100%;
        height: var(--link-indicator-height);
        // background: transparent;
        // transition: background 500ms ease-in;

        &.has-child {
          height: 0;
        }
      }
    }

    .link-line-item {
      padding-left: calc(var(--link-left) - var(--link-indicator-width));
      flex: 1;

      a {
        padding: calc(var(--link-top) / 2) var(--link-left);
        padding-left: 0;
        display: block;
      }
    }

    &.first-level {
      // padding-left: size(16);

      >.active-indicator {
        display: none;
      }

      >.link-line-item {
        padding-left: 0;
      }
    }

    &:hover {
      > .active-indicator {
        .box {
          background: var(--hero-text);
        }
      }
    }

    &.active {
      > .active-indicator {
        .box {
          background: var(--master-highlight);
        }
      }
      > .link-line-item {
        a {
          color: var(--hero-text);
        }
      }
    }
  }

  &.level-2, &.level-3, &.level-4 {
    --link-indicator-width: #{size(5)};
    --link-left: #{size(8)};

    .menu-name {
      font-weight: normal;
      padding-left: 0;
      padding-top: var(--link-top);
    }

    ul {
      border-left: 1px solid var(--gray-line);
      margin: calc(var(--link-top) / 2) var(--level-n-left-pad);
      margin-right: 0;

      a {
        color: var(--secondary-text);

        &:hover {
          color: var(--hero-text);
        }
      }

      // li {
      //   &:first-child {
      //     .link-line-item {
      //       a {
      //         padding-top: 0;
      //       }
      //     }
      //   }
      // }
    }
  }
}

@import "@/assets/styles/global/lib.scss";
























































































































































































section.hero {

  padding-top: var(--section-pad-top);
  h2 {
    font-family: var(--hero-font);
    font-size: var(--section-title-font-size);
    line-height: var(--section-title-line-height);
    margin-bottom: size(10);
  }

  .section-desc-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .image-area {
      height: var(--hero-img-height);
      flex: 1 0 auto;
    }
  }
  &.sub-section, .error-code-section {
    padding-top: var(--subsection-pad-top);
    padding-left: var(--subsection-pad-left);
    h2 {
      font-size: var(--title-2-font-size);
      line-height: var(--title-2-line-height);
    }
    .section-desc-area {
      .image-area{
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(210);
          display: block;
        }
      }
    }
  }

  &.error-code-section {
    padding-left: var(--section-pad-left);
    .section-desc-area {
      padding-bottom: var(--subsection-pad-top);
      padding-left: var(--codesection-pad-left);
    }
    &.show_border .section-desc-area {
      border-bottom: size(2) solid var(--gray-line);
    }
  }

  &.single-column {
    h2 {
      font-size: var(--title-2-font-size);
      line-height: var(--title-2-line-height);
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .section-desc-area {
      flex-direction: row;

      .image-area {
        min-width: size(335);
      }
    }
  }
}

@import "@/assets/styles/global/lib.scss";


















































































.api-request-structure{
  --label-width: #{size(221)};
  --item-content-padding-top: #{size(16)};
  --item-content-padding-bottom: #{size(18)};
  --item-padding-bottom: #{size(24)};
  --req-font-size: #{size(12)};
  --req-line-height: #{size(20)};
  --req-label-font-size: #{size(12)};
  --req-label-line-height: #{size(15)};
  --req-token-font-size: #{size(10)};
  --req-token-line-height: #{size(13)};
  --req-desc-font-size: #{size(14)};
  --req-desc-line-height: #{size(17)};
  --hero-color-with-opacity: rgba(0, 83, 214, 0.2);

  font-size: var(--req-font-size);

  @media screen and (min-width: $breakpoint-md) {
    --item-content-padding-bottom: #{size(20)};
    --req-token-font-size: #{size(12)};
    --req-token-line-height: #{size(15)};
    --req-label-font-size: #{size(14)};
    --req-label-line-height: #{size(17)};
    --req-font-size: #{size(14)};
    --req-line-height: #{size(20)};
    --item-padding-bottom: #{size(32)};
    --req-desc-font-size: #{size(16)};
    --req-desc-line-height: #{size(19)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --item-content-padding-top: #{size(19)};
    --req-line-height: #{size(20)};
    --item-padding-bottom: #{size(36)};
    --req-desc-font-size: #{size(18)};
    --req-desc-line-height: #{size(24)};
    --req-label-font-size: #{size(16)};
    --req-label-line-height: #{size(19)};
  }

  .request-item {
    padding-right: var(--docs-page-pad-secondary-right);
    .section-line-item {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--item-content-padding-bottom);
      &.url-syntax {
        margin-bottom: var(--item-padding-bottom);
        overflow: hidden;
        .section-line-item {
          margin-bottom: size(12);
          &:last-child {
            margin-bottom: 0;
          }
          @media screen and (min-width: $breakpoint-md) {
            margin-bottom: size(24);
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-bottom: size(20);
          }
          .item-value {
            overflow: auto;
          }
        }
      }

      &.host, &.method {
        flex-direction: row;
        align-items: flex-start;
        .item-value {
          font-size: var(--req-font-size);
          line-height: var(--req-line-height);
          padding-top: size(3);
          color: var(--secondary-text);
          @media screen and (min-width: $breakpoint-lg) {
            padding-top: size(5);
          }
          .highlight-object {
            font-weight: 700;
            color: var(--hero-text);
            background: none;
            padding: 0;
          }
        }
      }
      &.method {
        margin-bottom: 0;
      }
      .host-item, .method-item {
        border: size(1) solid var(--hero-text);
        padding: size(4) size(8);
        border-radius: size(19);
        font-size: var(--req-token-font-size);
        line-height: var(--req-token-line-height);
        text-transform: uppercase;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(5) size(8);
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(7) size(10);
        }
      }
      .host-item {
        color: var(--hero-text);
        margin-right: size(12);
        @media screen and (min-width: $breakpoint-md) {
          margin-right: size(15);
        }
         @media screen and (min-width: $breakpoint-lg) {
          margin-right: size(12);
        }
      }
      .method-item {
        color: var(--regular-text-inv);
        background: var(--hero-text);
        margin-right: size(12);
        @media screen and (min-width: $breakpoint-md) {
          margin-right: size(19);
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-right: size(16);
        }
      }

      >label {
        display: block;
      }

      .item-label, label {
        padding-bottom: size(4);
        min-width: var(--label-width);
        font-weight: bold;
        border-bottom: size(1) solid var(--regular-text-inv);
        color: var(--hero-text);
        font-size: var(--req-label-font-size);
        line-height: var(--req-label-line-height);
        margin-bottom: var(--item-content-padding-top);

        @media screen and (min-width: $breakpoint-lg) {
          padding-bottom: size(8);
        }

        // @media screen and (min-width: $breakpoint-lg) {
        //   font-weight: normal;
        // }
      }

      .item-value {
        flex-grow: 1;

        &.space-top {
          padding-top: size(20);
        }
      }
    }

    .section-url {
      .section-line-item {
        .item-value {
          font-family: var(--code-font);
          // Fira code font is bigger compared to other font,
          // keep it smaller

          &.url-item {
            .p {
              font-size: 1em;
              line-height: 1.4;
            }

            .url-item-wrapper {
              padding-top: size(16);

              &:first-child {
                padding-top: 0;
              }
            }
          }
        }
      }
    }

    .section-params {
      .section-line-item {
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
          .item-value {
            .param-item {
              &:last-child {
                .param-item-property {
                  padding-bottom: 0;
                }
              }
              .param-item-property {
                .name {
                  @media screen and (min-width: $breakpoint-md) {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }
        .item-value {
          &:last-child {
            margin-bottom: 0;
          }
          .param-item {
            &:first-child {
              .param-item-property {
                padding-top: 0;
              }
            }

            .param-item-property {
              padding: size(6) 0;
              flex-direction: column;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(12) 0;
              }
              @media screen and (min-width: $breakpoint-lg) {
                display: flex;
                flex-direction: row;
                padding: size(16) 0;
              }
              .type-desc {
                display: inline;
              }

              .name {
                padding-right: size(12);
                font-size: var(--req-font-size);
                line-height: var(--req-line-height);
                font-weight: 700;
                color: var(--hero-text);
                font-family: var(--code-font);
                display: inline-block;
                text-align: left;
                @media screen and (min-width: $breakpoint-md) {
                  padding-right: size(24);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  min-width: var(--label-width);
                  text-align: right;
                }
              }

              .item-type-req {
                display: inline-block;
                .type {
                  padding: size(6) size(4);
                  margin-left: size(-1);
                  border-radius: size(14);
                  background: var(--hero-text);
                  color: var(--regular-text-inv);
                  font-family: var(--code-font);
                }
                .req {
                  display: inline-block;
                  padding: size(5.25) size(6);
                  padding-left: 0;
                  border-radius: size(14);
                  color: var(--hero-text);
                  font-style: normal;
                  text-transform: capitalize;
                  background: transparent;
                  border: size(0.75) solid var(--hero-text);
                  margin-bottom: size(8);
                  &.required {
                    padding: size(6);
                    padding-left: 0;
                    background: var(--hero-color-with-opacity);
                    border: none;
                  }
                  @media screen and (min-width: $breakpoint-lg) {
                    border: size(1) solid var(--hero-text);
                  }
                }
              }

              .req, .type {
                font-size: var(--req-token-font-size);
                line-height: var(--req-token-line-height);
              }

              .help {
                .markdown {
                  .p {
                    font-size: var(--req-desc-font-size);
                    line-height: var(--req-desc-line-height);

                    a {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .markdown {
    a {
      color: var(--hero-text);
    }

    em, h1, h2, h3, h4 {
      font-weight: 600;
      color: var(--secondary-text);
    }

    h1, h2, h3, h4 {
      padding-bottom: size(8);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
  }

  @media screen and (min-width: $breakpoint-lg) {
    .markdown {
      h1, h2, h3, h4 {
        padding-bottom: size(12);
      }
    }
  }
}
